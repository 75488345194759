import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Sidebar from '.././components/sidebar'
import '../styles/main.scss'
import '../styles/fonts/font-awesome/css/font-awesome.min.css'

function DefaultLayout({children}) {
  return(  
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              author
              description
            }
          }
        }
      `}
      render={data => (
        <div className="wrapper">
          <Helmet>
            {/* Fediverse creator tags */}
            <meta name="fediverse:creator" content="@mergesort@macaw.social" />
            <meta name="fediverse:creator" content="@mergesort@threads.net" />
          </Helmet>
          
          <Sidebar siteMetadata={data.site.siteMetadata} />
          {children}
          
          {/* Hidden rel="me" links for verification */}
          <a rel="me" href="https://build.ms"></a>
          <a rel="me" href="https://blog.plinky.app"></a>
          <a rel="me" href="https://threads.net/@mergesort"></a>
          <a rel="me" href="https://bsky.app/profile/@mergesort.me"></a>
          <a rel="me" href="https://threads.net/@plinky.app"></a>
          <a rel="me" href="https://bsky.app/profile/@plinky.app"></a>
          <a rel="me" href="https://macaw.social/@mergesort"></a>
        </div>
      )}
  />
);
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout;
